// actions.js


// Action types
export const SELECT_TILE = 'SELECT_TILE';
export const UPDATE_USER = 'UPDATE_USER'; // Example for another state
export const SET_BASEURL = 'SET_BASEURL';
// Sidebar visibility action types
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const SET_MODE = 'SET_MODE';
export const SET_SELECTED_NODE = 'SET_SELECTED_NODE';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const RESET_HISTORY = 'RESET_HISTORY';


// Action creators
export const selectTile = (tileId) => ({
  type: SELECT_TILE,
  payload: tileId,
});


export const updateUser = (userData) => ({
  type: UPDATE_USER,
  payload: userData,
});


// Action creators for sidebar
export const showSidebar = () => ({
  type: SHOW_SIDEBAR,
});


export const hideSidebar = () => ({
  type: HIDE_SIDEBAR,
});
export const setBaseURL = (baseURL) => ({
  type: SET_BASEURL,
  payload: baseURL,
});


// Workflow action creator
export const setMode = (mode) => ({
  type: SET_MODE,
  payload: mode,
})


export const setSelectedNode = (nodeId) => ({
  type: SET_SELECTED_NODE,
  payload: nodeId,
});

export const updateBreadcrumbs = breadcrumbs => ({
  type: UPDATE_BREADCRUMBS,
  payload: breadcrumbs
});

export const resetHistory = () => ({
  type: RESET_HISTORY
});

