import React, { useState, useEffect } from 'react';
import styles from './NodePropertiesModal.module.css';
import AppearanceSettings from './AppearanceSettings';
import ConfigurationSection from './ConfigurationSection';
import MappingAndTransformationSection from '../MappingAndTransformationSection/MappingAndTransformationSection';

const NodePropertiesModal = ({ node, onUpdate, onClose, nodes, edges }) => {
  const [nodeData, setNodeData] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    if (node && node.data) {
      setNodeData(node.data); // Ensure node data is updated correctly
      const allSections = Object.keys(node.data);
      const firstSection = allSections.includes('properties') ? 'properties' : allSections[0];
      setActiveSection(firstSection);
    }
  }, [node]);

  const handleSave = () => {
    onUpdate({ ...node, data: nodeData }); // Pass the updated nodeData to parent
    onClose();
  };

  const updateNodeData = (newData) => {
    setNodeData(newData); // Update nodeData state
  };

  const renderSectionContent = () => {
    switch (activeSection) {
      case 'properties':
        return (
          <AppearanceSettings
            label={nodeData.properties?.label || ''}
            background={nodeData.properties?.styles?.background || '#ffffff'}
            borderRadius={nodeData.properties?.styles?.borderRadius || 0}
            icon={nodeData.properties?.icon || ''}
            setLabel={(label) => updateNodeData({ ...nodeData, properties: { ...nodeData.properties, label } })}
            setBackground={(background) =>
              updateNodeData({
                ...nodeData,
                properties: { ...nodeData.properties, styles: { ...nodeData.properties.styles, background } },
              })
            }
            setBorderRadius={(borderRadius) =>
              updateNodeData({
                ...nodeData,
                properties: { ...nodeData.properties, styles: { ...nodeData.properties.styles, borderRadius } },
              })
            }
            setIcon={(icon) => updateNodeData({ ...nodeData, properties: { ...nodeData.properties, icon } })}
          />
        );
      case 'configurations':
        return (
          <ConfigurationSection
            configuration={nodeData.configurations}
            onSave={(configurations) => {
              updateNodeData({ ...nodeData, configurations }); // Update nodeData when configurations are saved
            }}
          />
        );
      case 'mapping-and-transformations': // New section for mapping and transformations
        return (
          <MappingAndTransformationSection
            node={node}
            nodes={nodes}
            edges={edges}
            onUpdateNode={onUpdate}
          />
        );
      default:
        return <div>No section selected</div>;
    }
  };

  return (
    <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
      <div className={styles.sectionToggle}>
        {Object.keys(nodeData).map((sectionKey) => (
          <button
            key={sectionKey}
            className={`${styles.toggleButton} ${activeSection === sectionKey ? styles.active : ''}`}
            onClick={() => setActiveSection(sectionKey)}
          >
            {sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)}
          </button>
        ))}
        {/* Add a toggle button for the new 'mapping-and-transformations' section */}
        <button
          className={`${styles.toggleButton} ${activeSection === 'mapping-and-transformations' ? styles.active : ''}`}
          onClick={() => setActiveSection('mapping-and-transformations')}
        >
          Mapping & Transformations
        </button>
      </div>
      <div className={styles.contentContainer}>
        {renderSectionContent()}
        <div className={styles.actionButtons}>
          <button type="button" className={styles.saveButton} onClick={handleSave}>
            Save
          </button>
          <button type="button" className={styles.cancelButton} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default NodePropertiesModal;
