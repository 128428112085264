// components/nodes/AppearanceSettings.js

/**
 * AppearanceSettings Component
 *
 * This component provides input fields for configuring the appearance of a node.
 * It allows users to set the label, background color, and border radius of the node.
 *
 * Main Features:
 * - Input field for setting the node's label.
 * - Color picker for choosing the node's background color.
 * - Input field for defining the node's border radius.
 *
 * Author: Yadu Dev
 * Date: July 2024
 */

import React from 'react';
import styles from './AppearanceSettings.module.css'; // Updated the import to match the new CSS file name

const AppearanceSettings = ({ label,setLabel}) => {

  return (
    <div className={styles.outerBox}>
      <div className={styles.list}>
        <div className={styles.inputWrapper}>
          <label className={styles.inputLabel}>Label:</label>
          <input type="text" value={label} className={styles.inputField} onChange={(e) => setLabel(e.target.value)} />
        </div>
      </div>
    </div>
  );
};

export default AppearanceSettings;