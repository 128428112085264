// useRouteBreadcrumbs.js
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useDispatch, useSelector } from 'react-redux';
import { updateBreadcrumbs } from '../redux/actions/actions';

const useRouteBreadcrumbs = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate
    const dispatch = useDispatch();
    const existingBreadcrumbs = useSelector(state => state.breadcrumbs.breadcrumbs);

    // Define the sidebar paths here
    const sidebarPaths = ['/reports', '/user-management', '/my-templates', '/logs', '/job-schedule', '/profile', '/user-inbox', '/settings', '/integrations','/workflow-library'];

    useEffect(() => {
        // Split the current path into segments (e.g., '/my-templates/workflow-builder/3' becomes ['my-templates', 'workflow-builder', '3'])
        const pathSnippets = location.pathname.split('/').filter(i => i);

        // Generate new breadcrumbs based on the current path
        const newBreadcrumbs = pathSnippets.map((snippet, index) => {
            const path = '/' + pathSnippets.slice(0, index + 1).join('/');
            return { path, label: snippet }; // Customize label based on your needs
        });

        // Ensure we always start with the root breadcrumb (e.g., Dashboard)
        const rootBreadcrumb = { path: '/dashboard', label: 'Dashboard' };

        // Reset breadcrumbs only when navigating to '/dashboard'
        if (location.pathname === '/dashboard') {
            if (existingBreadcrumbs.length !== 1 || existingBreadcrumbs[0].path !== rootBreadcrumb.path) {
                dispatch(updateBreadcrumbs([rootBreadcrumb])); // Reset to dashboard only
            }
            return; // Exit early to prevent further processing if we are on the dashboard
        }

        // Make sure we are not mutating the existingBreadcrumbs by creating a shallow copy
        const existingBreadcrumbsCopy = [...existingBreadcrumbs];

        // Find the **last** clicked sidebar path from the sidebarPaths array
        const lastSidebarCrumb = existingBreadcrumbsCopy
            .slice() // Create a shallow copy for immutability
            .reverse() // Reverse the copy to get the last clicked sidebar path
            .find(crumb => sidebarPaths.includes(crumb.path)) || null;

        // Build the full breadcrumb trail starting from 'Dashboard'
        const fullBreadcrumbTrail = [rootBreadcrumb];

        // If there was a last sidebar-selected crumb, add it after 'Dashboard'
        if (lastSidebarCrumb) {
            fullBreadcrumbTrail.push(lastSidebarCrumb);
        }

        // Check if we need to append new paths to existing breadcrumbs without resetting
        const appendBreadcrumbs = () => {
            const lastExistingCrumbPath = existingBreadcrumbs[existingBreadcrumbs.length - 1]?.path;
            const lastNewCrumbPath = newBreadcrumbs[newBreadcrumbs.length - 1]?.path;

            // If the current breadcrumb trail already includes the last new breadcrumb, don't add it again
            if (lastExistingCrumbPath === lastNewCrumbPath) {
                return existingBreadcrumbsCopy; // No change needed, return the copy
            }

            // If we navigated deeper, append the new breadcrumbs to the existing flow
            return [
                ...fullBreadcrumbTrail, 
                ...newBreadcrumbs.filter(crumb => !existingBreadcrumbsCopy.some(existingCrumb => existingCrumb.path === crumb.path))
            ];
        };

        const updatedBreadcrumbs = appendBreadcrumbs();

        // Only dispatch if breadcrumbs have changed to prevent unnecessary re-renders
        if (
            updatedBreadcrumbs.length !== existingBreadcrumbsCopy.length ||
            updatedBreadcrumbs.some((crumb, index) => crumb.path !== existingBreadcrumbsCopy[index]?.path)
        ) {
            dispatch(updateBreadcrumbs(updatedBreadcrumbs)); // Do not reverse here, just update the new breadcrumbs
        }
    }, [location.pathname, dispatch, existingBreadcrumbs, sidebarPaths]); // Depend on pathname and sidebar paths

    return { breadcrumbs: existingBreadcrumbs, navigate }; // Return breadcrumbs and navigate function
};

export default useRouteBreadcrumbs;
