import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleIcon from '@mui/icons-material/People';
import ReportIcon from '@mui/icons-material/AssessmentOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ViewCompactAltOutlinedIcon from '@mui/icons-material/ViewCompactAltOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import MenuIcon from '@mui/icons-material/Menu'; // Menu button for mobile
import profileImage from '../assetts/images/profile-image.svg';
import { logoutRequest } from '../redux/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';


const menuItems = [
  {id:0, text: 'Dashboard', path: '/dashboard', icon: <DashboardOutlinedIcon /> },
  {id:1, text: 'User Management', path: '/user-management', icon: <PeopleIcon /> },
  {id:2, text: 'Reports', path: '/reports', icon: <ReportIcon /> },
  {id:3, text: 'Logs', path: '/logs', icon: <BusinessCenterOutlinedIcon /> },
  {id:4, text: 'Job Schedule', path: '/job-schedule', icon: <CalendarMonthOutlinedIcon /> },
  {id:5, text: 'My Templates', path: '/my-templates', icon: <ViewCompactAltOutlinedIcon /> },
  {id:6, text: 'Profile', path: '/profile', icon: <Person2OutlinedIcon /> },
  {id:7, text: 'User Inbox', path: '/user-inbox', icon: <InboxOutlinedIcon /> },
  {id:8, text: 'Settings', path: '/settings', icon: <SettingsOutlinedIcon /> },
];


const Sidebar = ({ isHidden, toggleSidebar }) => {
  // const isSidebarCollapsed = useSelector((state) => state.sidebar.isSidebarCollapsed);
  // const isVisible = useSelector((state) => state.sidebar.isVisible);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const firstName = useSelector((state) => state.auth.userDetails?.first_name || '');
  const lastName = useSelector((state) => state.auth.userDetails?.last_name || '');
  const email = useSelector((state) => state.auth.userDetails?.email || '');
  const [selectedItem, setSelectedItem] = useState(0);
  const dispatch = useDispatch();
  
  // useEffect(() => {
  //   if (shouldHideSidebar) {
  //     dispatch(hideSidebar());
  //   } else {
  //     dispatch(showSidebar());
  //   }
  // }, [shouldHideSidebar, dispatch]);
  // Update selectedIndex based on the current location (active route)
  useEffect(() => {
    const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
    setSelectedIndex(currentIndex);
  }, [location.pathname]); // Update when location changes

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleSideMenuClick = (index, path) => {
    setSelectedIndex(index);
    console.log('selectedIndex ',selectedIndex )
    console.log('index ',index)
    
    navigate(path);
  };
  const handleLogout = () => {
    // Close the menu before logging out
    handleMenuClose();
    // Dispatch the logout action
    dispatch(logoutRequest());
};
  const drawer = (
    <Box sx={{ overflow: 'hidden', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Avatar sx={{ mr: 2 }} src={profileImage} alt="Profile Image" />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ color:'#000000', fontSize: '18px', letterSpacing: '0.01em', lineHeight: '21.78px', fontWeight: '700' }}>
              {firstName + " "+lastName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'grey', fontSize: '13px' }}>
              {/* {email} */}
              Administrator
            </Typography>
          </Box>
        </Box>


        <IconButton edge="end" aria-label="more" aria-controls="profile-menu" aria-haspopup="true" onClick={handleMenuClick}>
          <MoreHorizIcon />
        </IconButton>


        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem sx={{color:'#000000'}} onClick={handleMenuClose}>Profile</MenuItem>
          <MenuItem sx={{color:'#000000'}} onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>


      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {index === menuItems.length - 3 && (
              <Divider
                sx={{
                  borderColor: 'grey.300',
                  borderBottomWidth: '1px',
                  borderStyle: 'dotted',
                  marginY: 3,
                }}
              />
            )}
            <ListItem
              button
              onClick={() => handleSideMenuClick(index, item.path)} 
              // sx={{ marginBottom: '12px' }}
              // style={{
              //   backgroundColor: selectedItem === item.id ? '#E0F7FA' : 'inherit', // Highlight selected item
              // }}
            >
              
              <ListItemIcon 
                sx={{
                  minWidth: '40px',
                  marginLeft: '15px',
                  // Conditional styling for selected item
                  color: selectedIndex === index ? '#232323' : '#6B6B6B', // Change color if selected
                  fontSize: selectedIndex === index ? '28px' : '24px', // Increase size if selected to simulate bold
                  transition: 'font-size 0.3s ease', // Smooth transition effect
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style: {
                    fontWeight: selectedIndex === item.id ? '700' : '400',  // Bold the selected item
                    color: selectedIndex === item.id ? '#232323' : '#6B6B6B',    // Change color of selected item

                  },
                }}
                // sx={{
                //   color: selectedIndex == index ? '#232323' : '#6B6B6B',
                //   fontWeight: selectedIndex == index ? 700 : 400,
                //   fontSize:'18.97px',
                // }}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );


  return (
    <div className={`sidebar ${isHidden ? 'hidden' : ''}`}>    
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          // open={isVisible}
          onClose={handleDrawerToggle}
          sx={{
            width: 263, // Full width of the drawer
            flexShrink: 0,
            transition: 'transform 0.3s ease', // Smooth sliding transition
            // transform: isVisible ? 'translateX(0)' : 'translateX(-263px)', // Move left when hidden
            [`& .MuiDrawer-paper`]: {
              width: 263, // Full width when open
              boxSizing: 'border-box',
              overflowX: 'hidden',
              backgroundColor: '#F5F9FF',
              border: 'none',
              marginTop: '72px',
              height: 'calc(100vh - 72px)', // Full height minus topbar height
              zIndex: 1500,
              position: 'relative',
              transition: 'transform 0.3s ease', // Smooth transition effect for the drawer paper
              // transform: isVisible ? 'translateX(0)' : 'translateX(-263px)', // Move left when hidden
              '@media (max-width:600px)': {
                position: 'fixed',
                zIndex: 1300,
                width: '100%',
                height: '100%', // Full height for mobile screens
                // transform: isVisible ? 'translateX(0)' : 'translateX(-100%)', // Full left for mobile screens
              },
            },
          }}
        >
        {drawer}
         {/* // Thin line on the right side of the sidebar   */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: '1px', // Thin line
            backgroundColor: '#ECEEF6', // Color of the line
            zIndex: 1600, // Ensures it is above the sidebar
          }}
        />
      </Drawer>
      <IconButton
        onClick={toggleSidebar}
        sx={{
          position: 'absolute',
          top: 78,
          right: -28, // Adjust position as needed
          backgroundColor: isHidden ? '#F5F9FF' : '#F5F9FF', // Dynamic background color
          color: '#7398C7',
          borderRadius: '4px', // Creates the oval shape
          borderTopRightRadius: '8px', // Top-right corner radius
          borderBottomRightRadius: '8px', // Bottom-right corner radius
          padding: '2px 2px', // Adjusts padding for oval shape
          border: '1px solid #ECEEF6', // Add the border with the specified color
          '&:hover': {
            backgroundColor: '#F5F9FF', // Hover effect
          },
          zIndex: 160, // Ensure it's above other elements
          width: 'auto', // Ensure the width is flexible for oval shape
        }}
      >
        <MenuIcon fontSize="medium" />
      </IconButton>
     
     
    </div>
  );
};


export default Sidebar;