import React, { useCallback, useRef, useState } from 'react';
import {
  ReactFlow,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  Controls,
  useReactFlow,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import GenericNode from '../GenericNode/GenericNode';
import CustomEdge from '../CustomEdge/CustomEdge';
import NodePropertiesModal from '../NodePropertiesModal/NodePropertiesModal';
import DynamicPopup from '../../../components/reusableComponents/DynamicPopup/DynamicPopup';

const nodeTypes = {
  genericNode: GenericNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const Canvas = ({ nodes, setNodes, edges, setEdges, onInit }) => {
  const reactFlowWrapper = useRef(null);
  const { screenToFlowPosition, setViewport } = useReactFlow();
  const [selectedNode, setSelectedNode] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow'));

      if (!nodeData) return;

      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      const newNode = {
        id: `node_${new Date().toISOString()}`,
        type: 'genericNode',
        position,
        data: nodeData.data,
      };

      setNodes((nds) => [...nds, newNode]);

      // // Manually control zoom and position after drop
      // setViewport({ x: 0, y: 0, zoom: 0. }, { duration: 800 });
    },
    [setNodes, screenToFlowPosition, setViewport]
  );

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge({ ...params, type: 'custom', markerEnd: 'url(#arrow)' }, eds));
    },
    [setEdges]
  );

  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, [setNodes]);

  const onEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, [setEdges]);

  const onNodeClick = useCallback((event, node) => {
    event.stopPropagation();
    setSelectedNode(node);
    setIsModalVisible(true);
  }, []);

  const handleUpdateNode = useCallback((nodeData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => (node.id === nodeData.id ? { ...node, data: { ...node.data, ...nodeData.data } } : node))
    );
    setIsModalVisible(false);
  }, [setNodes]);

  return (
    <div
      className="reactflow-wrapper"
      ref={reactFlowWrapper}
      style={{ width: '100%', height: '100%' }}
      onDrop={onDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={onInit}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onNodeClick}
        fitView={false} // Disable auto zoom
      >
        <Background />
        <Controls />
      </ReactFlow>

      {isModalVisible && (
    <DynamicPopup 
        title={selectedNode?.data?.properties?.label} 
        onClose={() => setIsModalVisible(false)} 
        draggable={false}
        style={{ minHeight: '25rem', minWidth: '50rem' }} 
    >
      <NodePropertiesModal
        node={selectedNode}
        onUpdate={handleUpdateNode}
        onClose={() => setIsModalVisible(false)}
        nodes={nodes}
        edges={edges}
      />
    </DynamicPopup>
)}
    </div>
  );
};

export default Canvas;
