import React from 'react';
import styles from './ConfigurationField.module.css';
import minusIcon from '../../../assetts/images/minus.svg';

const ConfigurationField = ({ fieldKey, value, path, handleChange, handleRemoveField, multiple }) => {
  const inputType = value.type === 'number' ? 'number' : 'text';

  const snakeCaseToCapitalized = (str) =>
    str
      .split('_')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(' ');

  return (
    <div className={multiple ? styles.MultipleInputWrapper : styles.inputWrapper}>
      <label>{snakeCaseToCapitalized(fieldKey)}:</label>
      <input
        type={inputType}
        value={value.value}
        onChange={(e) => handleChange(path, e.target.value)}
        className={styles.inputField}
      />
      {!value.dynamic && (
        <img
          className={styles.removeButton}
          onClick={() => handleRemoveField(path)}
          src={minusIcon}
          alt="Remove"
        />
      )}
    </div>
  );
};

export default ConfigurationField;
