import React, { useState, useEffect, useCallback } from 'react';
import ConfigurationField from './ConfigurationField';
import ConfigurationHeader from './ConfigurationHeader';
import DynamicPopup from '../../../components/reusableComponents/DynamicPopup/DynamicPopup';
import NewFieldForm from '../forms/WidgetFieldForm';
import styles from './ConfigurationSection.module.css';

function ConfigurationSection({ configuration, onSave }) {
  const [config, setConfig] = useState(configuration || {});
  const [openSections, setOpenSections] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [currentPath, setCurrentPath] = useState([]);
  const [activeSection, setActiveSection] = useState(null);

  // Ensure all sections are open initially
  useEffect(() => {
    if(configuration) {
      const initializeOpenSections = (config, path = []) => {
        let sections = {};
        for (const key in config) {
          if (config[key].properties) {
            const newPath = [...path, key].join('.');
            sections[newPath] = true; // Open all sections by default
            Object.assign(sections, initializeOpenSections(config[key].properties, [...path, key]));
          }
        }
        return sections;
      };
  
      setOpenSections(initializeOpenSections(configuration));
      setActiveSection(Object.keys(configuration)[0]);
    }
    
  }, []);

  const toggleSection = useCallback((key) => {
    setOpenSections((prev) => ({ ...prev, [key]: !prev[key] }));
  }, []);

  const handleAddField = (path) => {
    setCurrentPath(path);
    setShowPopup(true);
  };

  const saveNewField = useCallback((key, value) => {
    setConfig((current) => {
      const newData = JSON.parse(JSON.stringify(current)); // Deep copy to avoid state mutation
      const section = currentPath.reduce((acc, cur) => acc[cur].properties, newData);

      section[key] = { type: 'string', value: value, dynamic: true };
      onSave(newData); // Pass updated configuration to parent
      return newData;
    });
    setShowPopup(false); // Close the popup
  }, [onSave, currentPath]);

  const handleRemoveField = useCallback((path) => {
    setConfig((current) => {
      const newData = JSON.parse(JSON.stringify(current));
      const section = path.slice(0, -1).reduce((acc, cur) => acc[cur].properties, newData);
      delete section[path[path.length - 1]];
      onSave(newData); // Pass updated configuration to parent
      return newData;
    });
  }, [onSave]);

  const handleChange = useCallback((path, value) => {
    setConfig((currentConfig) => {
      const newConfig = JSON.parse(JSON.stringify(currentConfig)); // Deep copy
      const updateValue = (configSegment, pathIndex) => {
        const key = path[pathIndex];
        if (pathIndex === path.length - 1) {
          configSegment[key].value = value; // Update the final value
        } else {
          updateValue(configSegment[key].properties, pathIndex + 1); // Recurse deeper
        }
      };
      updateValue(newConfig, 0);
      onSave(newConfig); // Propagate changes back to parent
      return newConfig;
    });
  }, [onSave]);

  // Renders the appropriate section and ensures the correct active section is displayed
  const renderSection = (properties, path) => {
    if (!properties) return null;

    const normalFields = Object.entries(properties).filter(([key, value]) => !value.properties);
    const headerFields = Object.entries(properties).filter(([key, value]) => value.properties);

    return (
      <ul className={styles.list}>
        <div className={styles.gridContainer}>
          {normalFields.map(([key, value]) => (
            <ConfigurationField
              key={key}
              fieldKey={key}
              value={value}
              path={[...path, key]}
              handleChange={(p, v) => handleChange(p, v)}
              handleRemoveField={(p) => handleRemoveField(p)}
              multiple={normalFields.length > 1}
            />
          ))}
        </div>
        {headerFields.map(([key, value]) => (
          <ConfigurationHeader
            key={key}
            fieldKey={key}
            value={value}
            path={[...path, key]}
            openSections={openSections}
            toggleSection={toggleSection}
            renderSection={(props, path) => renderSection(props, path)}
            handleAddField={handleAddField}
          />
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.configuration}>
      <div className={styles.tabSwitch}>
        {configuration ? Object.keys(config).map((sectionKey) => (
          <button
            key={sectionKey}
            className={`${styles.tabButton} ${activeSection === sectionKey ? styles.activeTab : ''}`}
            onClick={() => setActiveSection(sectionKey)}
          >
            {sectionKey}
          </button>
        )) : <span>Not fond any configurations</span>}
      </div>

      {activeSection && renderSection(config[activeSection]?.properties, [activeSection])}

      {showPopup && (
        <DynamicPopup title="Add New Field" onClose={() => setShowPopup(false)}>
          <NewFieldForm onSave={saveNewField} onClose={() => setShowPopup(false)} />
        </DynamicPopup>
      )}
    </div>
  );
}

export default ConfigurationSection;
