import { createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers'; // Assuming you have combined reducers here
import rootSaga from '../sagas/rootSaga'; // Import your root saga
import authSaga from '../sagas/authSaga'
import { watchTokenExpiration } from '../sagas/watchTokenExpiration';
import authReducer from '../reducers/authReducer';
// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: {
//     auth: authReducer, // Your auth reducer here
//     // other reducers
//   },
//   middleware: [sagaMiddleware],
// });

//Create the Redux store with Saga middleware
// const store = createStore(
//   rootReducer, // Your combined reducers
//   applyMiddleware(sagaMiddleware)
// );
const store = configureStore({
  reducer: rootReducer, // Your combined reducers here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }) // Disabling thunk as we are using saga
      .concat(sagaMiddleware), // Adding saga middleware
});
// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
