import React, { createContext, useContext, useState } from 'react';

const DnDContext = createContext();

export const DnDProvider = ({ children }) => {
  const [draggedNodeType, setDraggedNodeType] = useState(null);

  return (
    <DnDContext.Provider value={{ draggedNodeType, setDraggedNodeType }}>
      {children}
    </DnDContext.Provider>
  );
};

export const useDnD = () => useContext(DnDContext);
