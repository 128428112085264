import React, { useState, useRef, useEffect } from 'react';
import styles from './CustomDropdown.module.css';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import { ReactComponent as DropdownIconOpen } from '../../../assetts/images/dropdownIconOpen.svg';

function CustomDropdown({ label, items, value, onChange, disabled, style }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (selectedValue) => {
    const event = {
      target: {
        name: 'custom-dropdown',
        value: selectedValue,
      },
    };
    onChange(event);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedItemLabel = items.find((item) => item.value === value)?.label || 'Select';

  return (
    <div className={styles.dropdownContainer} style={style}>
      {label && <label className={styles.dropdownLabel}>{label}:</label>}
      <div className={styles.customDropdown} ref={dropdownRef} style={style}>
        <div
          className={`${styles.dropdownHeader} ${disabled ? styles.disabled : ''}`}
          style={style}
          onClick={handleToggle}
        >
          <span>{selectedItemLabel}</span>
          {isOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>
        {isOpen && (
          <ul className={styles.dropdownList}>
            {items.map((item, index) => (
              <li
                key={index}
                className={`${styles.dropdownItem} ${item.value === value ? styles.selected : ''}`}
                onClick={() => handleSelect(item.value)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default CustomDropdown;
