import React, { useState, useEffect } from 'react';
import { CssBaseline, Box } from '@mui/material';
import WorkflowHeader from '../../components/WorkflowHeader/WorkflowHeader';
import FilterSidebar from '../../components/FilterSidebar/FilterSidebar';
import WorkflowGrid from '../../components/WorkflowGrid/WorkflowGrid';
import styles from './WorkflowLibraryPage.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidebar, showSidebar } from '../../../../redux/actions/actions';
import { fetchWorkflowTilesApi, fetchTagsApi } from '../../../../api/workflowApi'; // Import API functions
import Preloader from '../../../../components/common/Preloader';

const WorkflowLibraryPage = () => {
    const [filters, setFilters] = useState([]);
    const [tiles, setTiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const accessToken = useSelector((state) => state.auth.accessToken);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    // Initial fetch for tags and workflows
    useEffect(() => {
        dispatch(hideSidebar());

        const fetchInitialData = async () => {
            try {
                if (isAuthenticated && accessToken) {
                    const tags = await fetchTagsApi(accessToken);
                    setFilters(tags);

                    // Initial workflow fetch with default params
                    const tilesData = await fetchWorkflowTilesApi(accessToken, { name: "", tags: [] });
                    setTiles(tilesData.results);
                } else {
                    throw new Error('User is not authenticated');
                }
            } catch (err) {
                setError(err.message || 'Failed to load workflow tiles or filters');
            } finally {
                setLoading(false);
            }
        };

        fetchInitialData();

        return () => {
            dispatch(showSidebar());
        };
    }, [accessToken, isAuthenticated, dispatch]);

    // Triggered on tag selection
    const handleTagSelection = async (selectedTagIds) => {
        setLoading(true);

        try {
            const tilesData = await fetchWorkflowTilesApi(accessToken, { name: "", tags: selectedTagIds });
            setTiles(tilesData.results); // Update only the tiles state
        } catch (err) {
            setError(err.message || 'Failed to fetch workflows with selected tags');
        } finally {
            setLoading(false);
        }
    };
    // if (loading) {
    //     return <Preloader />; // Show preloader while loading
    // }
    return (
        <Box
            sx={{
                width: '100%',
                margin: '0 auto',
                overflowX: 'hidden',
            }}
        >
            <CssBaseline />
            <div className={styles.workflowLibraryPage}>
                <div className={styles.headerAndContent}>
                    <WorkflowHeader />
                    <div className={styles.mainContent}>
                        {/* Pass handleTagSelection to FilterSidebar */}
                        <FilterSidebar filters={filters} onTagSelectionChange={handleTagSelection} />
                        <div className={styles.contentContainer}>
                            {/* WorkflowGrid will re-render when tiles are updated */}
                            <WorkflowGrid tiles={tiles} loading={loading} error={error} />
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default WorkflowLibraryPage;
