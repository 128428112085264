import React, { useState, useEffect } from 'react';
import { CssBaseline, AppBar, Toolbar, Box, IconButton, Badge } from '@mui/material';
import { useDispatch } from 'react-redux';
import DetailViewHeader from '../../components/DetailViewHeader/DetailViewHeader';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import { setMode } from '../../../../redux/actions/actions';
import logo from '../../../../assetts/images/RUNMYBOT_logo2.svg';
import DetailViewSidebar from '../../components/DetailViewSidebar/DetailViewSidebar';
import AutomationDetailsPage from '../../components/AutomationDetails/AutomationDetails';
import styles from './DetailViewPage.module.css';

const DetailViewPage = () => {
  const dispatch = useDispatch()
  const [filters, setFilters] = useState([]);
  const [tiles, setTiles] = useState([]);
  const [selectedLog, setSelectedLog] = useState({}); // New state for selected log ID
  useEffect(() => {
    dispatch(setMode('view'));
    import('../../data/filterOptions.json').then((data) => setFilters(data.default));
    import('../../data/workflowTiles.json').then((data) => setTiles(data.default));
  }, []);

  return (
   
      <div className={styles.DetailViewPage}>
        <div className={styles.headerAndContent}>
          <DetailViewHeader />
          <div className={styles.mainContent}>
            {/* Pass a callback to update the selected log ID */}
            <DetailViewSidebar filters={filters} onLogSelect={setSelectedLog} />
            <div className={styles.contentContainer}>
              {/* Pass the selected log ID to AutomationDetailsPage */}
              <AutomationDetailsPage selectedLog={selectedLog} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default DetailViewPage;
