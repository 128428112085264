import React, { useState, useEffect } from 'react';
import styles from './DetailViewSidebar.module.css';
import { useSelector } from 'react-redux';
import { ReactComponent as SystemSettingsIcon } from '../../../../assetts/images/systemSettings.svg';
import { ReactComponent as WarningIcon } from '../../../../assetts/images/warning.svg';
import { ReactComponent as SuccessIcon } from '../../../../assetts/images/success.svg'; // Add Success icon import
import { ReactComponent as ErrorIcon } from '../../../../assetts/images/error.svg'; // Add Error icon import
import { ReactComponent as PendingIcon } from '../../../../assetts/images/pending.svg'; // Add Pending icon import
import CustomDropdown from '../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import { fetchProcessFlowListApi } from '../../../../api/processFlowApi';

const DetailViewSidebar = ({ host, onLogSelect, fromDate, toDate }) => {
    const accessToken = useSelector((state) => state.auth.accessToken);

    const [logs, setLogs] = useState([]);  // To store logs fetched from API
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [highlightedLog, setHighlightedLog] = useState(null);
    const [error, setError] = useState(null);  // To store errors if fetching logs fails

    // Fetch logs from the API
    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const logsData = await fetchProcessFlowListApi(accessToken, {
                    from_date: fromDate || '2022-10-10',
                    to_date: toDate || '2022-10-10',
                    status: selectedStatus === 'All' ? '' : selectedStatus.toLowerCase(),
                    workflow_id: '' // Modify as necessary
                });
                setLogs(logsData.results);  // Set the logs from the response
                setError(null);  // Reset error if successful
                onLogSelect(logsData?.results?.[0]);
            } catch (error) {
                setError('Error fetching logs. Please try again later.');
                console.error('Error fetching logs:', error);
            }
        };

        fetchLogs();
    }, [selectedStatus, accessToken, fromDate, toDate]);

    const handleStatusChange = (value) => {
        setSelectedStatus(value);  // Update selected status
    };

    const handleLogClick = (log) => {
        setHighlightedLog(log.id);  // Highlight clicked log
        onLogSelect(log);  // Notify parent component with the selected log ID
    };

    // Define dropdown items for statuses
    const statusOptions = [
        { value: 'All', label: 'All' },
        { value: 'Warning', label: 'Warning' },
        { value: 'Error', label: 'Error' },
        { value: 'Success', label: 'Success' },
        { value: 'Pending', label: 'Pending' }
    ];

    const filteredLogs = logs.filter((log) => {
        if (selectedStatus === 'All') return true;
        return log.status.toLowerCase() === selectedStatus.toLowerCase();
    });

    const getStatusIcon = (status) => {
        switch (status.toLowerCase()) {
            case 'warning':
                return <WarningIcon className={styles.statusIcon} />;
            case 'success':
                return <SuccessIcon className={styles.statusIcon} />;
            case 'error':
                return <ErrorIcon className={styles.statusIcon} />;
            case 'pending':
                return <PendingIcon className={styles.statusIcon} />;
            default:
                return <p>*</p>;  // Default fallback for undefined statuses
        }
    };

    const getStatusStyles = (status) => {
        switch (status.toLowerCase()) {
            case 'success':
                return { '--border-color': '#62E4B5' };
            case 'error':
                return { '--border-color': '#EF6A6A' };
            case 'pending':
                return { '--border-color': '#E4C554' };
            case 'warning':
                return { '--border-color': '#E27DDD' };
            default:
                return { '--border-color': '#C4C4C5' }; // Default grey color
        }
    };
    
    return (
        <div className={styles.sidebar}>
            <div className={styles.filterSection}>
                {/* Date Filter */}
                <div className={styles.filterItem}>
                    <div className={styles.filterHeader}>
                        <SystemSettingsIcon className={styles.filterIcon} />
                        <span className={styles.filterTitle}>Date*</span>
                    </div>
                    <input
                        type="text"
                        value={fromDate || 'August-01-2024 12:30 AM'}
                        readOnly
                        className={styles.dateInput}
                    />
                    <span className={styles.dateSeparator}>to</span>
                    <input
                        type="text"
                        value={toDate || 'August-13-2024 11:59 PM'}
                        readOnly
                        className={styles.dateInput}
                    />
                </div>
    
                {/* Status Filter with CustomDropdown */}
                <div className={styles.filterItem}>
                    <div className={styles.filterHeader}>
                        <SystemSettingsIcon className={styles.filterIcon} />
                        <span className={styles.filterTitle}>Status*</span>
                    </div>
                    <div className={styles.statusDropdown}>
                        <CustomDropdown
                            items={statusOptions.map(({ value, label }) => ({
                                value,
                                label,
                            }))}
                            value={selectedStatus}
                            onChange={(e) => handleStatusChange(e.target.value)}
                            style={{
                                width: '100%',
                                maxWidth: '261px',
                                height: '53px',
                                borderRadius: '12px',
                                fontSize: '18.97px',
                                lineHeight: '22.96px',
                                color: '#6B6B6B',
                            }}
                        />
                    </div>
                </div>
            </div>
    
            <div className={styles.listSection}>
                {error ? (
                    <p className={styles.error}>{error}</p>
                ) : (
                    <ul className={styles.logList}>
                        {filteredLogs.map((log) => {
                            const statusStyles = getStatusStyles(log.status);
    
                            return (
                                <div
                                    key={log.id}
                                    draggable
                                    className={`${styles.logItem} ${highlightedLog === log.id ? styles.highlighted : ''}`}
                                    style={statusStyles}  // Apply inline style for pseudo-elements
                                    onClick={() => handleLogClick(log)}
                                >
                                    <div className={styles.curveLine}></div>
                                    <div className={styles.dateTime}>
                                    <span className={`${styles.logDate} ${highlightedLog === log.id ? styles.highlighted : ''}`}>
                                        {log.date}
                                    </span>
                                    <span className={`${styles.logTime} ${highlightedLog === log.id ? styles.highlighted : ''}`}>
                                        {log.time}
                                    </span>
                                    </div>
                                    {getStatusIcon(log.status)}
                                </div>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
    
    
};

export default DetailViewSidebar;
