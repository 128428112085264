import React from 'react';
import { Routes, Route } from 'react-router-dom';
import useRouteBreadcrumbs from './hooks/useRouteBreadcrumbs';
import Settings from './pages/Settings';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reports from './pages/Reports';
import Profile from './pages/Profile';
import UserManagement from './pages/UserManagement';
import Logs from './pages/Logs';
import JobSchedule from './pages/JobSchedule';
import MyTemplates from './pages/MyTemplates';
import UserInbox from './pages/UserInbox';
import PrivateRoute from './PrivateRoute';

import Dashboard from './pages/Dashboard';
import DashboardOverview from './components/DashboardOverview';
import WorkflowLibraryPage from './pages/workflows/pages/WorkflowLibraryPage/WorkflowLibraryPage';
import IntegrationsLibraryPage from './pages/integrations/pages/IntegrationsLibraryPage/IntegrationsLibraryPage';
import DetailViewPage from './features/DetailView.js/pages/DetailViewPage/DetailViewPage';

import WorkflowBuilder from './features/workflowBuilder/WorkflowBuilder/WorkflowBuilder';

const AppRoutes = () => {
  useRouteBreadcrumbs();
  return (
    <Routes>
       <Route path="/login" element={<Login />} />
       <Route path="/signup" element={<Signup />} />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="dashboard" element={<DashboardOverview />} />
          <Route path="integrations" element={<IntegrationsLibraryPage />} />
          <Route path="workflow-library" element={<WorkflowLibraryPage />} />
          <Route path="user-management" element={<UserManagement />} />
          <Route path="job-schedule" element={<JobSchedule />} />
          <Route path="my-templates" element={<MyTemplates />} />
          <Route path="user-inbox" element={<UserInbox />} />
          <Route path="logs" element={<Logs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="reports" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="/process-flow/:id" element={<DetailViewPage />} />
          <Route path="/workflow-builder" element={<WorkflowBuilder />} />
          <Route path="/workflow-builder/:id" element={<WorkflowBuilder />} />
        </Route>

        <Route path="/" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
