import React, { useState, useEffect } from 'react';
import MappingAndTransformationTabs from './MappingAndTransformationTabs';
import styles from './MappingAndTransformationSection.module.css';

const MappingAndTransformationSection = ({ node, nodes, edges, onUpdateNode }) => {
  const [mappings, setMappings] = useState(node.data?.mappings || {});
  const [transformations, setTransformations] = useState(node.data?.transformations || {});

  // Get connected nodes (sources and targets) for the current node
  const getConnectedNodes = () => {
    const connectedSources = [];
    const connectedTargets = [];

    if (!edges || !Array.isArray(edges) || !nodes || !Array.isArray(nodes)) {
      console.error('Edges or nodes are not defined or not an array.');
      return { connectedSources, connectedTargets };
    }

    edges.forEach((edge) => {
      // Find source nodes that connect to the current node
      if (edge.target === node.id) {
        const sourceNode = nodes.find((n) => n.id === edge.source);
        if (sourceNode) connectedSources.push(sourceNode);
      }

      // Find target nodes that connect from the current node
      if (edge.source === node.id) {
        const targetNode = nodes.find((n) => n.id === edge.target);
        if (targetNode) connectedTargets.push(targetNode);
      }
    });

    return { connectedSources, connectedTargets };
  };

  const { connectedSources, connectedTargets } = getConnectedNodes();

  // Handle save action to persist mappings and transformations
  const handleSave = () => {
    onUpdateNode({
      ...node,
      data: {
        ...node.data,
        mappings,
        transformations,
      },
    });
  };

  return (
    <div className={styles.mappingTransformationContainer}>
      <MappingAndTransformationTabs
        node={node}
        connectedSources={connectedSources}
        connectedTargets={connectedTargets}
        mappings={mappings}
        setMappings={setMappings}
        transformations={transformations}
        setTransformations={setTransformations}
      />

      {/* <div className={styles.actionButtons}>
        <button className={styles.saveButton} onClick={handleSave}>Save Mappings & Transformations</button>
      </div> */}
    </div>
  );
};

export default MappingAndTransformationSection;
