import React, { useEffect, useState } from 'react';
import { ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import styles from './Workflows.module.css';
import GenericNode from '../workflowBuilder/GenericNode/GenericNode';
import CustomEdge from '../workflowBuilder/CustomEdge/CustomEdge';

const nodeTypes = {
  genericNode: GenericNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const WorkflowCard = ({ workflowData, onNodeClick }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  useEffect(() => {
    if (workflowData) {
      // Set nodes and edges from the passed workflowData
      setNodes(workflowData?.graph?.nodes);
      setEdges(workflowData?.graph?.edges);
    }
  }, [workflowData]);

  // Handle node click
  const handleNodeClick = (event, node) => {
    onNodeClick(node.id); // Call parent function to set selected node ID
  };

  return (
    <div className={styles.card}>
      {nodes?.length > 0 && edges?.length > 0 ? (
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultZoom={2}
          className={styles.reactFlowContainer}
          onNodeClick={handleNodeClick} // Pass the click handler
        />
      ) : (
        <p>Loading workflow...</p>
      )}
    </div>
  );
};

export default WorkflowCard;
