import React, { useState } from 'react';
import styles from './AutomationFlowComponent.module.css';
import WorkflowCard from '../../../workflows/Workflows';
import cancelIcon from '../../../../assetts/images/cancel-s.svg';

const AutomationFlowComponent = ({ log, workflowData, onNodeSelected }) => {

  // Handle node click
  const handleNodeClick = (nodeId) => {
    onNodeSelected(nodeId);
  };
  return (
    <div className={styles.automationFlowContainer}>
      <div className={styles.header}>
        <h3 className={styles.dateTime}>
          <span>{log.date}</span>
          <span>{log.time}</span>
        </h3>
        {log?.status == 'success' && <button className={styles.cancelButton}>
          <img src={cancelIcon} alt="Cancel Icon" />
          <span className={styles.cancelButtonText}>Cancel Run</span>
        </button>}
      </div>
      <div className={styles.flowDiagram}>
        {workflowData ? (
          <WorkflowCard 
            workflowData={workflowData} 
            onNodeClick={handleNodeClick}
          />
        ) : (
          <p>Loading workflow...</p>
        )}
      </div>
    </div>
  );
};

export default AutomationFlowComponent;
