// src/forms/WidgetFieldForm.js
/**
 * WidgetFieldForm Component
 *
 * This component renders a form that allows users to add new fields or widgets to a configuration.
 * It supports selecting the type of input (e.g., text, number, email) or widget (e.g., Auth, Parameters)
 * and provides input fields for key-value pairs based on the selection.
 * 
 * Main Features:
 * - Dropdown to select between 'Input' or 'Widgets'.
 * - Dynamic input fields based on the selected type.
 * - Validates the form before saving.
 * - Invokes onSave and onClose callbacks on successful form submission.
 * 
 * Author: Yadu Dev
 * Date: July 2024
 */

import React, { useState } from 'react';
import styles from './WidgetFieldForm.module.css';
import CustomDropdown from '../../../components/reusableComponents/CustomDropdown/CustomDropdown';

const WidgetFieldForm = ({ onSave, onClose }) => {
  const [selection, setSelection] = useState('Input');
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const [inputType, setInputType] = useState('text');
  const [widget, setWidget] = useState('');

  const handleSave = () => {
    if (selection === 'Input' && key && value !== null) {
      onSave(key, value);
      onClose();
    } else if (selection === 'Widgets' && widget) {
      onSave('widget', widget);
      onClose();
    } else {
      alert('Please complete the form.');
    }
  };

  const handleSelectionChange = (value) => {
    setSelection(value);
    setKey('');
    setValue('');
    setWidget('');
  };

  return (
    <div className={styles.form}>
     <p>Developement inprogress</p>
    </div>
  );
};

export default WidgetFieldForm;
