import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './WorkflowHeader.module.css';
import backArrowRounded from '../../../assetts/images/leftRoundedArrow.svg';
import edit from '../../../assetts/images/edit.svg';
import { generateBreadcrumbElements } from '../../../utils/breadcrumbUtils'; // Import the utility function

const WorkflowHeader = ({ setShowSavePopup, title, setTitle, apiType }) => {
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const breadcrumbs = useSelector(state => state.breadcrumbs.breadcrumbs);

  const handleTitleClick = () => setIsEditing(true);
  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleTitleBlur = () => setIsEditing(false);
  const handleBackButtonClick = () => navigate(-1);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <div className={styles.row}>
          {isEditing ? (
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              autoFocus
              className={styles.titleInput}
            />
          ) : (
            <div className={styles.row}>
              <h1 className={styles.title} onClick={handleTitleClick}>
                {title || 'Click to edit title'}
              </h1>
              <img src={edit} onClick={handleTitleClick} alt="edit" className={styles.editIcon} />
            </div>
          )}
        </div>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)} {/* Use the utility function */}
        </div>
      </div>
      <div className={styles.actionsSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        {apiType === 'workflow' && (
          <>
            <button className={styles.actionButton}>Run Now</button>
            <button className={styles.actionButton}>Deactivate Workflow</button>
          </>
        )}
        <button className={styles.actionButton}>Scheduler</button>
        <button className={styles.actionButton} onClick={() => setShowSavePopup(true)}>Save Workflow</button>
      </div>
    </div>
  );
};

export default WorkflowHeader;
