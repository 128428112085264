import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchWorkflowNodesApi } from '../../../api/workflowApi'; 
import { useDnD } from '../DnDProvider/DnDProvider';
import { transformNodeData } from '../helpers/transformNodeData';
import styles from './Sidebar.module.css';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import { ReactComponent as SearchIcon } from '../../../assetts/images/searchIcon.svg';
import Tooltip from '@mui/material/Tooltip';

const Sidebar = () => {
    const { setDraggedNodeType } = useDnD();
    const [expandedCategories, setExpandedCategories] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Store search term
    const [nodes, setNodes] = useState({}); // Store node data internally in Sidebar
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // Store debounced value
    const accessToken = useSelector((state) => state.auth.accessToken); // Get access token from Redux store

    const toggleExpand = (categoryKey) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryKey]: !prev[categoryKey],
        }));
    };

    const onDragStart = (event, node) => {
        event.dataTransfer.setData('application/reactflow', JSON.stringify(node));
        event.dataTransfer.effectAllowed = 'move';
        setDraggedNodeType(node);
    };

    const filterNodes = (categoryNodes) => {
        return categoryNodes.nodes.filter((node) =>
            node.data.properties.label.toLowerCase().includes(debouncedSearchTerm)
        );
    };

    // Debounce logic for the search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm); // Update the debounced term after delay
        }, 300); // 300ms debounce delay

        return () => {
            clearTimeout(handler); // Clear timeout if the searchTerm changes before the delay ends
        };
    }, [searchTerm]);

    // Perform the search by calling the API when the debouncedSearchTerm changes or initially when it's empty
    useEffect(() => {
        const performSearch = async () => {
            try {
                const params = debouncedSearchTerm ? { name: debouncedSearchTerm } : {}; // Only pass search term if it's not empty
                const incomingData = await fetchWorkflowNodesApi(accessToken, params); // Call the API
                const transformedData = transformNodeData(incomingData); // Transform the incoming data
                setNodes(transformedData); // Update the node data internally
            } catch (error) {
                console.error('Error fetching nodes:', error);
            }
        };

        performSearch(); // Call the API on every debounced search input change or initially

    }, [debouncedSearchTerm, accessToken]); // Trigger the search when debouncedSearchTerm changes

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase()); // Update searchTerm on every input change
    };

    return (
        <div className={styles.sidebarContainer}>
            <div className={styles.searchContainer}>
                <SearchIcon className={styles.searchIcon} />
                <input
                    type="text"
                    placeholder="Search Integrations..."
                    className={styles.searchInput}
                    value={searchTerm} // Set the current search term as input value
                    onChange={handleSearch} // Handle search change directly
                />
            </div>

            <aside className={styles.sidebar}>
                {Object.entries(nodes).map(([categoryKey, categoryData]) => (
                    <div className={styles.categorySection} key={categoryKey}>
                        {/* Category Header */}
                        <div
                            className={styles.categoryHeader}
                            onClick={() => toggleExpand(categoryKey)}
                        >
                            {/* Render the groupIcon as an image */}
                            <img
                                src={categoryData.groupIcon}
                                alt={`${categoryData.groupLabel} icon`}
                                className={styles.categoryIcon}
                            />
                            <Tooltip title={categoryData.groupLabel} arrow>
                                <span className={styles.categoryTitle}>
                                    {categoryData.groupLabel}
                                </span>
                            </Tooltip>
                            <DropdownIconClosed
                                className={`${styles.dropdownIcon} ${
                                    expandedCategories[categoryKey] ? styles.expanded : ''
                                }`}
                            />
                        </div>

                        {/* Show the items when expanded */}
                        {expandedCategories[categoryKey] && (
                            <div className={styles.nodeList}>
                                {filterNodes(categoryData).map((node) => (
                                    <div
                                        key={node.id}
                                        onDragStart={(event) => onDragStart(event, node)}
                                        draggable
                                        className={styles.nodeItem}
                                    >
                                        <div className={styles.curveLine}></div>
                                        <span className={styles.nodeLabel}>
                                            {node.data.properties.label}
                                        </span>
                                        <div className={styles.nodeIcon}>
                                            <img 
                                                src={node.data.properties.icon}
                                                alt={node.data.properties.label}
                                                className={styles.nodeIconImage}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </aside>
        </div>
    );
};

export default Sidebar;
