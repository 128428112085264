// sagas/rootSaga.js

import { all } from 'redux-saga/effects';
import authSaga, { watchReAuthenticate, watchRegister } from '../sagas/authSaga'; // Import the watcher saga for registration
import { watchTokenExpiration } from  '../sagas/watchTokenExpiration'

export default function* rootSaga() {
    console.log(".... auth SAGA")
    yield all([
        authSaga(),       // This will handle login and refresh token sagas
        watchRegister(),  // This will handle the registration saga
        watchTokenExpiration(), // This will handle the token expiration 
        watchReAuthenticate(), 
        // other sagas...
    ]);
}
