// sagas/authSaga.js


import { call, put, select, takeLatest } from 'redux-saga/effects';

import {

   RE_AUTHENTICATE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    logoutFailure,
    REFRESH_TOKEN_REQUEST,
    refreshTokenSuccess,
    refreshTokenFailure,
    REGISTER_REQUEST,
    registerSuccess,
    registerFailure,
    storeTokens,
    clearTokens,
    storeUserDetails
} from '../actions/authActions';


import { loginApi, refreshTokenApi } from '../../api/authApi'
import { isTokenValid } from '../../utils/tokenUtils';


// Worker Saga: Handling login API call
function* handleLogin(action) {
    try {
        const response = yield call(loginApi, action.payload);
        const { access, refresh, user_id, first_name, last_name, email } = response;
        console.log(' response', response);
        // const { accessToken, refreshToken } = response;
        if (response.access && response.refresh) {
            // Store access token and refresh token in localStorage
            // yield put({ type: LOGIN_SUCCESS, payload: { access, refresh } });
            yield put({ type: 'STORE_TOKENS', payload: { accessToken: access, refreshToken: refresh } });
            // yield put(storeTokens(access, refresh));
            console.log(' accessToken accessTokenaccessToken ', access);
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
     
            // Optionally store user information
            localStorage.setItem('userId', user_id);
            localStorage.setItem('firstName', first_name);
            localStorage.setItem('lastName', last_name);
            // localStorage.setItem('email', email);
            const accessToken = yield select((state) => state.auth.accessToken);
            console.log('Login accessToken :',accessToken);
            // Dispatch login success
            yield put(loginSuccess('Login successful!'));
            // Dispatch user details to the Redux store
            yield put(storeUserDetails({ user_id, first_name, last_name, email }));
            // Optionally navigate to the dashboard after login
            // You may need to use history.push('/dashboard') or a similar method
          } else {
            // Dispatch login failure if no tokens are found in the response
            yield put(loginFailure('Login failed. Please try again.'));
          }
        // Dispatch success action
        yield put({ type: LOGIN_SUCCESS, payload: {
          userDetails: {
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
          },
          access, refresh} });
        // yield put(loginSuccess({
        //     accessToken: response.access,
        //     refreshToken: response.refresh,


        // }));
    } catch (error) {
        // yield put(loginFailure(error.message));
        yield put({ type: LOGIN_FAILURE, payload: error.message });
    }
}


// Worker Saga: Handling token refresh API call
function* handleRefreshToken() {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) throw new Error('No refresh token found');


        const response = yield call(refreshTokenApi, { refreshToken }); // Call refresh API
        const { accessToken } = response;


        // Update access token in localStorage
        localStorage.setItem('accessToken', accessToken);


        // Dispatch success action
        yield put({ type: LOGIN_SUCCESS, payload: { accessToken, refreshToken } });
        // const refreshToken = yield select(state => state.auth.refreshToken);
        // const response = yield call(refreshTokenApi, refreshToken);
       
        // if (response.access) {
        //     yield put(refreshTokenSuccess(response.access));
        // } else {
        //     yield put(refreshTokenFailure());
        // }
    } catch (error) {
        // yield put(refreshTokenFailure());
        yield put({ type: LOGIN_FAILURE, payload: error.message });
    }
}




// API call for registration
function registerApi(userData) {
    console.log("Calling API with:", userData);
    return fetch('https://staging.runmybot.com/api/register/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(response => response.json());
  }
 
  // Worker saga
  function* handleRegister(action) {
    console.log("handleRegister... ",action)
    try {
      const response = yield call(registerApi, action.payload);
      console.log('API Response:', response);
      if (response.errors && response.errors[0] && response.errors[0].detail) {
       
        const errorMessage = response.errors[0].detail;
        yield put(registerFailure(errorMessage));  // Dispatch failure with the specific error message
      } else if (response.message) {
        yield put(registerSuccess('Registration successful!'));
      } else {
        yield put(registerFailure('Registration failed. Please try again.'));
      }
    } catch (error) {
      yield put(registerFailure('An error occurred during registration.'));
    }
  }
 
  // Watcher saga
  export function* watchRegister() {
    console.log('watchRegister is watching REGISTER_REQUEST...');
    yield takeLatest(REGISTER_REQUEST, handleRegister);
  }
// Watcher saga for refresh token
  export function* watchTokenExpiration() {
    console.log('watchRegister is watching REFRESH_TOKEN_REQUEST...');
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
}


// Watcher Saga: Watches for login and refresh token actions
export default function* authSaga() {
    yield takeLatest(LOGIN_REQUEST, handleLogin);
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
}


// Worker saga: handles the logout process
function* handleLogout() {
  try {
    // You can also clear tokens from localStorage or cookies if you're storing them there
    yield put(clearTokens()); // Dispatch action to clear tokens from the store
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    // Optionally, you can call an API to log the user out on the server if necessary
    // yield call(apiLogout);
    yield put(logoutSuccess());
    // Redirect to login page
    // yield put(push('/login')); // Redirect to login page using react-router


  } catch (error) {
    console.error('Logout failed:', error);
  }
}
// Watcher saga: listens for LOGOUT action
export function* watchLogoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}

// Saga to check if the token is valid on app load
export function* reAuthenticate() {
  console.log("Re-authenticate saga triggered");
  try {
    const token = localStorage.getItem('accessToken'); // Retrieve token from localStorage
    console.log( "reAuthenticate... token ",token)
    if (token && isTokenValid(token)) {
      // If the token is valid, dispatch login success and mark as authenticated
      yield put({ type: LOGIN_SUCCESS, payload: { token } });
    } else {
      // If the token is not valid, clear any stored tokens and mark as not authenticated
      yield put({ type: LOGIN_FAILURE });
    }
  } catch (error) {
    console.error('Error during re-authentication:', error);
    yield put({ type: LOGIN_FAILURE });
  }
}

// Watcher saga for re-authentication on app initialization
export function* watchReAuthenticate() {
  console.log('watchReAuthenticate ***** ')
  yield takeLatest(RE_AUTHENTICATE, reAuthenticate);
}