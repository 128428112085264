import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Button } from '@mui/material';
import integrationimage from '../assetts/images/Integration.svg';
import workflowimage from '../assetts/images/Workflow.svg';
import './Activities.css';
import { Link, useNavigate } from 'react-router-dom';

const Activities = () => {
  const navigate = useNavigate();
    // Handle button click event
  const handlIntegrationButtonClick = () => {
    // Logic to handle the click event
    navigate('/integrations');
    // You can add more functionality here, like navigation, opening a modal, etc.
  };
  const handleWorkflowButtonClick = () => {
    // Logic to handle the click event
    navigate('/workflow-library');
    // You can add more functionality here, like navigation, opening a modal, etc.
  };
  console.log("Choice screen")
  return (
    <Box className="activitiesContainer">
    <Grid container spacing={2}>
      {/* Integration Card */}
      <Grid item xs={12} sm={6}>
        <Card className="imageCard" sx={{ height: '400px' }}> {/* Set a fixed height */}
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box className="imageContainer">
              <img
                  src={integrationimage}
                  alt="Integrations"
                  style={{
                    width: '23%',
                    height: 'auto',
                    
                  }}
                />
            </Box>

            <Box>
              <Typography sx={{ color:'text.secondary', textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em' }}>
                Integrations
              </Typography>
              <Typography variant="body1" className="cardText">
                Integrations are connections between two or more applications, services, or systems that allow them to work together seamlessly.
              </Typography>
              <Typography variant="body1" className="cardText">
                Select from our available integrations to link your applications and protocols.
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'left', marginBottom:5}}> 
              <button className="viewButton_integration" onClick={handlIntegrationButtonClick}>View and Choose</button>
            </Box>
          </CardContent>
        </Card>
      </Grid>

  {/* Workflow Card */}
      <Grid item xs={12} sm={6}>
        <Card className="imageCard" sx={{ height: '400px' }}> {/* Same fixed height */}
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          
            <Box className="imageContainer">
            <img
                src={workflowimage}
                alt="Workflow Library"
                style={{
                  
                  width: '30%',
                  height: 'auto',
                  
                }}
              />
              {/* <img
                src={workflowimage}
                alt="Workflow Library"
                className="cardImage"
                style={{
                  marginTop: '10px',
                  width: '50%',
                  height: 'auto',
                  
                }}
              /> */}
            </Box>

            <Box>
              <Typography sx={{ color:'text.secondary',  textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em' }}>
                Workflow Library
              </Typography>
              <Typography variant="body1" className="cardText">
                Our library provides ready-made solutions with predefined workflows for quick and efficient integration of specific applications, services, or systems.
              </Typography>
              <Typography variant="body1" className="cardText">
                Unlock new automation possibilities with our extensive collection of prebuilt templates.
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'left', marginBottom:5 }}>
              <button className="viewButton_workflow" onClick={handleWorkflowButtonClick}>View and Choose</button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    </Box>
    // <Box className="activitiesContainer">
    //   <Grid container spacing={5}>
    //     <Grid item xs={12} sm={6}>
    //       <Card className="imageCard">
    //         <CardContent>
    //           <Box className="imageContainer">
    //             <img
    //               src={integrationimage}
    //               alt="Integrations"
    //               className="cardImage"
    //               style={{
    //                 marginTop:'10px',
    //                 width: '150%',
    //                 height: 'auto',
    //                 objectFit: 'none', // Prevent any fitting that could cause masking
    //               }}
    //             />
    //           </Box>

    //           <Typography sx={{ marginBottom:-2, textAlign: 'left',fontSize: '20px', fontWeight:600, lineHeight:'24.2px', letterSpacing:'0.01em' }}>
    //             <h4>Integrations</h4>
    //           </Typography>
    //           <Typography variant="body1" className="cardText" >
    //             Integrations are connections between two or more applications, services, or systems that allow them to work together seamlessly.
    //             </Typography>
    //             <Typography variant="body1" className="cardText">  
    //             Select from our available integrations to link your applications and protocols.
    //           </Typography>
    //           <Box sx={{ textAlign: 'left', marginTop: 'auto' }}>
    //               <button className="viewButton_integration" onClick={handlIntegrationButtonClick}>View and Choose</button>
    //           </Box>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} sm={6}>
    //       <Card className="imageCard">
    //         <CardContent>
    //           <Box className="imageContainer2">
    //             <img
    //               src={workflowimage}
    //               alt="Workflow Library"
    //               className="cardImage"
    //               style={{
    //                 marginTop:'15px',
    //                 width: '100%',
    //                 height: 'auto',
    //                 objectFit: 'none', // Prevent any fitting that could cause masking
                  
    //               }}
    //             />
    //           </Box>
    //           <Typography sx={{marginBottom:-2,  textAlign: 'left',fontSize: '20px', fontWeight:600, lineHeight:'24.2px', letterSpacing:'0.01em' }}>
    //             <h4>Workflow Library</h4>
    //           </Typography>
    //           <Typography variant="body1" className="cardText" sx ={{marginBottom:0}} >
    //             Our library provides ready-made solutions with predefined workflows for quick and efficient integration of specific applications, services, or systems.
    //             </Typography>
    //             <Typography variant="body1" className="cardText">    
    //             Unlock new automation possibilities with our extensive collection of prebuilt templates.
    //           </Typography>
    //           <Box sx={{ textAlign: 'left', marginTop: 'auto' }}>
    //             <button className="viewButton_workflow" onClick={handleWorkflowButtonClick}>View and Choose</button>
    //           </Box>
    //         </CardContent>
    //       </Card>
    //     </Grid>
    //   </Grid>
    // </Box>

  );
};

export default Activities;