import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import styles from './FilterSidebar.module.css';
import { ReactComponent as SystemSettingsIcon } from '../../../../assetts/images/systemSettings.svg';
import { ReactComponent as DropdownIconClosed } from '../../../../assetts/images/dropdownIconClosed.svg'; 
import { ReactComponent as DropdownIconOpen } from '../../../../assetts/images/dropdownIconOpen.svg';
import { ReactComponent as SearchIcon } from '../../../../assetts/images/searchIcon.svg';

const FilterSidebar = ({ filters, onTagSelectionChange }) => {
    const [isOpen, setIsOpen] = useState(true);  // To handle sidebar opening/closing
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');  // Store search term
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');  // Store debounced search term

    const handleToggle = () => {
        setIsOpen(!isOpen);  // Toggle the open state
    };

    const handleCheckboxChange = (tagId) => {
        const newSelectedTags = selectedTags.includes(tagId)
            ? selectedTags.filter((id) => id !== tagId)  // Remove tag if already selected
            : [...selectedTags, tagId];  // Add tag if not selected

        setSelectedTags(newSelectedTags);
        onTagSelectionChange(newSelectedTags);  // Trigger the parent callback to handle API call
    };

    // Debounce logic for search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);  // 300ms delay for debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);  // Re-run effect when searchTerm changes

    // Filter the tags based on the search input
    const filteredFilters = filters.filter((filter) =>
        filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);  // Update search term on every input change
    };

    return (
        <div className={styles.filterSidebar}>
            {/* Search Input */}
            <div className={styles.searchContainer}>
                <SearchIcon className={styles.searchIcon} />
                <input
                    type="text"
                    placeholder="Search..."
                    className={styles.searchInput}
                    value={searchTerm}  // Set the current search term as input value
                    onChange={handleSearch}  // Handle search change
                />
                <hr className={styles.dottedLine} />
            </div>

            {/* Filter List */}
            <div className={styles.menu}>
                <div className={styles.listHeader} onClick={handleToggle}>
                    <div className={styles.listHeaderContent}>
                        <SystemSettingsIcon className={styles.icon} />
                        <span className={styles.sectionTitle}>By System</span>
                    </div>
                    {isOpen ? (
                        <DropdownIconOpen className={styles.expandIcon} />
                    ) : (
                        <DropdownIconClosed className={styles.expandIcon} />
                    )}
                </div>

                {isOpen && (
                    <ul className={styles.filterList}>
                        {/* Display filtered or full list of filters */}
                        {filteredFilters.map((filter) => (
                            <li key={filter.id} className={styles.listItem}>
                                <input
                                    type="checkbox"
                                    checked={selectedTags.includes(filter.id)}  // Persist checkbox state
                                    className={styles.checkbox}
                                    onChange={() => handleCheckboxChange(filter.id)}  // Trigger API on change
                                />
                                <Tooltip title={filter.name} arrow>
                                    <span className={styles.listItemText}>{filter.name}</span>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default FilterSidebar;
