import { combineReducers } from 'redux';

import authReducer from './authReducer';
import workflowReducer from './workflowReducer';
import breadcrumbReducer from './breadcrumbReducer';

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer, 
  workflow: workflowReducer,
  breadcrumbs: breadcrumbReducer,
});

export default rootReducer;
