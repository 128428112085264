import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExpand } from '@fortawesome/free-solid-svg-icons';
import Draggable from 'react-draggable';
import styles from './DynamicPopup.module.css';

const DynamicPopup = ({ title, children, onClose, draggable = true, style = {} }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const updateDimensions = () => {
        if (isExpanded) {
            return {
                width: 'calc(100% - 263px)',
                height: '100%',
                top: '4.5rem',
                left: '0',
                transform: 'none',
                borderRadius: '0',
            };
        } else {
            return {
                minWidth: style.minWidth || '25rem',
                minHeight: style.minHeight || '15rem',
                top: '6.25rem',
                left: `calc(50% - ${Math.min(window.innerWidth * 0.9, 1200) / 2}px)`,
                transform: 'none',
                borderRadius: '8px',
            };
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const popupStyle = updateDimensions();

    const popupContent = (
        <div
            className={styles.popupContainer}
            style={{
                width: popupStyle.width,
                height: popupStyle.height,
                top: popupStyle.top,
                left: popupStyle.left,
                position: 'fixed',
                transform: popupStyle.transform,
                borderRadius: popupStyle.borderRadius,
                overflow: 'hidden',
                zIndex: 1000,
                minWidth: popupStyle.minWidth,
                minHeight: popupStyle.minHeight,
                maxWidth: isExpanded ? 'none' : '80vw',
                maxHeight: isExpanded ? 'none' : '80vh',
                ...style,
            }}
        >
            <div className={styles.headerWrapper}>
                <h4 className={styles.popupHeader}>{title}</h4>
                <div className={styles.iconWrapper}>
                    <FontAwesomeIcon
                        icon={faExpand}
                        className={styles.expandButton}
                        onClick={toggleExpand}
                    />
                    <FontAwesomeIcon
                        icon={faClose}
                        className={styles.closeButton}
                        onClick={onClose}
                    />
                </div>
            </div>
            <div className={styles.contentContainer}>
                {children}
            </div>
        </div>
    );

    return (
        <>
            <div className={styles.overlay} onClick={onClose} />
            {draggable && !isExpanded ? (
                <Draggable>
                    {popupContent}
                </Draggable>
            ) : (
                popupContent
            )}
        </>
    );
};

export default DynamicPopup;
