import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Routes';
import AuthProvider from './contexts/AuthContext'
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import store from './redux/store/store';
import { useDispatch } from 'react-redux';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch re-authenticate action when the app loads
    console.log("RE_AUTHENTICATE")
    dispatch({ type: 'RE_AUTHENTICATE' });
  }, [dispatch]);

  return (
    <Provider store={store}>
      <ThemeProvider  theme={theme}>
      <AuthProvider>
        <Router>
          <AppRoutes />
        </Router>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;