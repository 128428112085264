import React from 'react';
import styles from './IntegrationTile.module.css';
import { useNavigate } from 'react-router-dom';
import infoPink from '../../../../assetts/images/infoPink.svg';
import settingsBlue from '../../../../assetts/images/settingsBlue.svg';
import CustomTooltip from '../../../../components/reusableComponents/CustomTooltip/CustomTooltip';

const IntegrationTile = ({ id, icon, title, category, onClick, info }) => {
  const navigate = useNavigate();
  const handleNavigate = (url, state = {}) => {
    if (id) {
      navigate(url, { state });
    } else {
      console.warn('No ID provided for navigation.');
    }
  };

  return (
    <div className={styles.tile} style={{ cursor: 'pointer' }} role="button" onClick={onClick}>
      <div className={styles.tileContent}>
        <div className={styles.iconContainer}>
          <img src={icon} alt="Icon" className={styles.tileIcon} />
        </div>
        <div className={styles.title} title={title}>
          {title}
        </div>
      </div>
      <div className={styles.footer}>
        <span className={styles.category}>{category}</span>
        <div className={styles.actions}>
          <CustomTooltip title={info}>
            <img src={infoPink} alt="Info" className={styles.infoIcon} />
          </CustomTooltip>
            <img
              src={settingsBlue}
              alt="Settings"
              className={styles.actionIcon}
              onClick={() => handleNavigate(`/workflow-builder/${id}`, { type: 'basicIntegration' })}
            />
        </div>
      </div>
    </div>
  );
};

export default IntegrationTile;
