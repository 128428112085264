import React from 'react';
import IntegrationTile from '../IntegrationTile/IntegrationTile';
import styles from './IntegrationsGrid.module.css';

import dataIcon from '../../../../assetts/images/data.svg';
import catalogIcon from '../../../../assetts/images/catalog.svg';
import cartIcon from '../../../../assetts/images/cart.svg';

const iconMap = {
  data: dataIcon,
  catalog: catalogIcon,
  cart: cartIcon,
};

const IntegrationsGrid = ({ tiles, loading, error }) => {
  if (loading) {
    return <div>Loading integrations...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.gridContainer}>
      {tiles.map((tile) => (
        <IntegrationTile
        id={tile.id}
        icon={iconMap[tile.icon] || iconMap['data']}
        title={tile.name}
        category={tile.functions.join('/')}
          onClick={() => console.log(`Tile ${tile.id} clicked`)}
        info={tile.info}
        />
      ))}
    </div>
  );
};

export default IntegrationsGrid;
