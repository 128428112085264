// api/authApi.js
import { isTokenValid, refreshAccessToken } from '../utils/tokenUtils';

import { setAccessToken, logoutUser } from '../redux/actions/authActions';

export const loginApi = async (credentials) => {
    const response = await fetch('https://staging.runmybot.com/api/login/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    });

    if (!response.ok) {
        throw new Error('Failed to login');
    }

    return await response.json();
};

export const refreshTokenApi = async ({ refreshToken }) => {
    const response = await fetch('https://staging.runmybot.com/api/token/refresh/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
        throw new Error('Failed to refresh token');
    }

    return await response.json();
};

export const fetchWithToken = async (url, options = {}) => {
    let accessToken = localStorage.getItem('accessToken');
    
    // Check if the access token is valid
    if (!isTokenValid(accessToken)) {
      try {
        // Refresh the token if expired
        accessToken = await refreshAccessToken();
        
        // Dispatch the new access token to Redux
       // store.dispatch(setAccessToken(accessToken));
        
      } catch (error) {
        // If refresh token fails, logout the user and clear localStorage
        //store.dispatch(logoutUser());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        
        // Optionally redirect to the login page
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
  
    // Include the access token in the Authorization header
    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${accessToken}`,
    };
  
    // Make the fetch request
    const response = await fetch(url, {
      ...options,
      headers,
    });
  
    return response;
  };