import React from 'react';
import { Toolbar, Box, Button, IconButton, Badge, AppBar, Typography } from '@mui/material';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import logo from '../assetts/images/RUNMYBOT_logo2.svg'; // Update with the correct path
import MenuIcon from '@mui/icons-material/Menu';
import { BorderBottom } from '@mui/icons-material';


const Navbar = (toggleSidebar) => {
  return (

    <AppBar position="fixed" style={styles.topbar}>
      <Toolbar sx={{ marginTop:'5px' }}>
      <img src={logo} alt="Logo" style={{ height: '60%', marginRight: 16 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <Button
            variant="contained"
            sx={{
              mr: 2,
              color: '#4F63BE',
              backgroundColor: '#FFFFFF',
              borderRadius: '24px',
              boxShadow: '0px 0px 8px 0px #00000014',
              '&:hover': {
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 0px 20px 0px #00000014', // Ensure shadow is consistent on hover
              },
            }}
          >
            Switch to Experience RUNMYBOT
          </Button>
          <IconButton sx={{ color: '#B3B3B3' }}>
            <Badge 
              badgeContent={0} 
              color="primary" 
              sx={{ 
                '& .MuiBadge-dot': { 
                  border: '0.1px solid #fff', // Thinner border
                  borderRadius: '50%',
                  width: '6px',  // Adjust width of the dot
                  height: '6px', // Adjust height of the dot
                },
                '& .MuiBadge-root': {
                  padding: '0 2px', // Reduced padding
                }
              }}
            >
              <CircleNotificationsOutlinedIcon sx={{ fontSize: 35 }}/>
            </Badge>
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
const styles = {
  topbar: {
    height:'72px',
    backgroundColor: '#F5F9FF',
    zIndex: 1000,
    borderBottom: '1px solid #D0D0D0',
    boxShadow: '0px 0px 0px 0px #00000014',
  },
};
export default Navbar;