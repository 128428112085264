// reducers/authReducer.js


import { isTokenValid } from '../../utils/tokenUtils';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    REFRESH_TOKEN_SUCCESS,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    STORE_TOKENS,
    CLEAR_TOKENS,
    STORE_USER_DETAILS,
    SET_ACCESS_TOKEN,
    LOGOUT_USER,
    RE_AUTHENTICATE,
    CLEAR_SIGNUP_MESSAGE
} from '../actions/authActions';


const initialState = {
    userDetails: {
        // isAuthenticated: localStorage.getItem('accessToken') && isTokenValid(localStorage.getItem('accessToken')),
        first_name: '',
        last_name: '',
        email: ''
      },
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    loading: false,
    error: null,
    registering: false,
    registrationSuccess: null,
    registrationError: null,
};


export default function authReducer(state = initialState, action) {
    console.log('auth reducer action.type ',action.type ,"payload ",action.payload)
    switch (action.type) {
        case REGISTER_REQUEST:
            return {
              ...state,
              registering: true,
              registrationSuccess: null,
              registrationError: null,
            };
          case REGISTER_SUCCESS:
            return {
              ...state,
              registering: false,
              registrationSuccess: action.payload,
            };
          case REGISTER_FAILURE:
            return {
              ...state,
              registering: false,
              registrationError: action.payload,
            };
            case CLEAR_SIGNUP_MESSAGE:
                return {
                    ...state,
                    registrationSuccess: '', // Clear the message
                };
        case LOGIN_REQUEST:
            return { ...state, loading: true, error: null };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
                loading: false,
            };
        case LOGIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
           
        case LOGOUT_SUCCESS:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                isAuthenticated: false,
                user: {},
            };
           
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.access,
            };
        case STORE_TOKENS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                isAuthenticated: true, 
            };
        case STORE_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.payload,
            };
        case LOGOUT_USER:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
            };
        case RE_AUTHENTICATE:
            return {
                ...state,
                // During re-authentication, keep the current state
                isAuthenticated: false, // Assume false initially until token is validated
                error: null
            };
          
        default:
            return state;
    }
}



