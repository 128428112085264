import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from '../../../redux/actions/actions';
import Sidebar from '../Sidebar/Sidebar';
import Canvas from '../Canvas/Canvas';
import { DnDProvider } from '../DnDProvider/DnDProvider';
import { ReactFlowProvider } from '@xyflow/react';
import WorkflowHeader from '../workflowHeader/WorkflowHeader';
import styles from './WorkflowBuilder.module.css';
import { fetchWorkflowDataApi, saveOrUpdateWorkflowApi } from '../../../api/workflowApi';
import DynamicPopup from '../../../components/reusableComponents/DynamicPopup/DynamicPopup';
import Loader from '../../../components/reusableComponents/Loader/Loader';

const WorkflowBuilder = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [workflowDetails, setWorkflowDetails] = useState({});
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State to store API error message
  const [loading, setLoading] = useState(true);
  const [showSavePopup, setShowSavePopup] = useState(false);
  
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  
  useEffect(() => {
    dispatch(setMode('edit'));

    const fetchWorkflowData = async () => {
      if (!isAuthenticated || !accessToken) {
        setErrorPopup(true);
        setLoading(false);
        return;
      }

      try {
        const apiType = location.state?.type || 'template';
        
        if (id) {
          const data = await fetchWorkflowDataApi(accessToken, id, apiType);

          if (data && data.schema) {
            setTitle(data?.name);
            setNodes(data.schema.nodes);
            setEdges(data.schema.edges);
          }
        }
      } catch (error) {
        console.error('Error fetching workflow data:', error);
        setErrorMessage('Failed to load workflow data.');
        setErrorPopup(true);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflowData();
  }, [isAuthenticated, accessToken, id, location.state?.type]);

  const handleSaveWorkflow = async () => {
    const workflowData = {
      name: title,
      schema: { nodes, edges },
    };
  
    const apiType = location.state?.type || 'template'; 
    const wfId = (apiType === 'basicIntegration' || apiType === 'basicWorkflow') ? null : id;
  
    try {
      const response = await saveOrUpdateWorkflowApi(accessToken, workflowData, wfId);
      console.log('Saved Workflow:', JSON.stringify(response, null, 2));
      setWorkflowDetails(response); 
      return true;
    } catch (error) {
      console.error('Error saving workflow:', error);
      setErrorMessage(error.message || 'Failed to save workflow.');
      setErrorPopup(true);
      return false;
    }
  };
  

  const handleSaveAndContinue = async () => {
    const success = await handleSaveWorkflow();
    if (success) {
      setSuccessPopup(true);
      setShowSavePopup(false);
      navigate('/my-templates');
    }
  };

  const handleSaveOnly = async () => {
    const success = await handleSaveWorkflow();
    if (success) {
      setSuccessPopup(true);
      setShowSavePopup(false);
    }
  };

  const handleClosePopup = () => {
    setSuccessPopup(false);
    setErrorPopup(false);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={styles.workflowBuilder}>
        <div className={styles.headerAndContent}>
          <WorkflowHeader 
            setShowSavePopup={setShowSavePopup} 
            setTitle={setTitle} 
            title={title || ""} 
            apiType={location.state?.type || 'template'} 
          />
          <div className={styles.mainContent}>
            <DnDProvider>
              <ReactFlowProvider>
                <Sidebar />
                <div className={styles.contentContainer}>
                  <Canvas nodes={nodes} setNodes={setNodes} edges={edges} setEdges={setEdges} />
                </div>
              </ReactFlowProvider>
            </DnDProvider>
          </div>
        </div>
      </div>

      {showSavePopup && (
        <DynamicPopup title={`Save ${title}`} onClose={() => setShowSavePopup(false)}>
          <div className={styles.popupContent}>
            <p>What would you like to do next?</p>
            <div className={styles.popupButtons}>
              <button className={styles.continueButton} onClick={handleSaveAndContinue}>
                Save & Continue
              </button>
              <button className={styles.saveButton} onClick={handleSaveOnly}>
                Save
              </button>
            </div>
          </div>
        </DynamicPopup>
      )}

      <Snackbar
        open={successPopup}
        autoHideDuration={3000}
        onClose={handleClosePopup}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClosePopup} severity="success" sx={{ width: '100%' }}>
          Workflow saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorPopup}
        autoHideDuration={6000} // Extend the duration to give users time to read
        onClose={handleClosePopup}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClosePopup} severity="error" sx={{ width: '100%' }}>
          {errorMessage} {/* Display the API error message */}
        </Alert>
      </Snackbar>
    </>
  );
};

export default WorkflowBuilder;
