import React, { useEffect, useState } from 'react';
import AutomationFlowComponent from './AutomationFlowComponent';
import RunDetailsComponent from './RunDetailsComponent';
import { fetchProcessFlowDetailApi } from '../../../../api/processFlowApi';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/reusableComponents/Loader/Loader';

const AutomationDetailsPage = ({ selectedLog }) => {
  const [workflowData, setWorkflowData] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [loading, setLoading] = useState(true); 
  const accessToken = useSelector((state) => state.auth.accessToken);

  const logId = selectedLog?.id;

  // Fetch workflow detail from API when logId changes
  useEffect(() => {
    const fetchWorkflowDetail = async () => {
      if (logId) {
        try {
          setLoading(true);
          const result = await fetchProcessFlowDetailApi(accessToken, logId);
          if (result) {
            setWorkflowData(result.results[0]); // Assuming results[0] is the workflow data
            setSelectedNodeId(result.results[0]?.graph?.nodes[0]?.id); // Set the first node as default
          }
        } catch (error) {
          console.error('Error fetching workflow details:', error);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      }
    };

    fetchWorkflowDetail();
  }, [logId, accessToken]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AutomationFlowComponent 
        log={selectedLog} 
        workflowData={workflowData} 
        onNodeSelected={setSelectedNodeId} 
      />
      <RunDetailsComponent 
        runDetails={workflowData?.graph?.nodes.find(node => node.id === selectedNodeId)?.data?.runDetails} 
      />
    </>
  );
};

export default AutomationDetailsPage;
