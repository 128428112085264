import React, { useState, useEffect } from 'react';
import styles from './IntegrationsSidebar.module.css';
import { Tooltip } from '@mui/material';
import { ReactComponent as SystemSettingsIcon } from '../../../../assetts/images/systemSettings.svg'; 
import { ReactComponent as DropdownIconClosed } from '../../../../assetts/images/dropdownIconClosed.svg'; 
import { ReactComponent as DropdownIconOpen } from '../../../../assetts/images/dropdownIconOpen.svg';
import { ReactComponent as SearchIcon } from '../../../../assetts/images/searchIcon.svg';

const IntegrationsSidebar = ({ filters, onTagSelectionChange }) => {
    const [isOpen, setIsOpen] = useState(true);  // To handle sidebar opening/closing
    const [selectedTag, setSelectedTag] = useState(null);  // Single selected tag
    const [searchTerm, setSearchTerm] = useState('');  // Store search term
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');  // Debounced search term

    const handleToggle = () => {
        setIsOpen(!isOpen);  // Toggle sidebar open/close
    };

    // Debounce search term to avoid sending API requests on every keystroke
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);  // Set debounced search term after delay
        }, 300);  // 300ms delay for debounce

        return () => {
            clearTimeout(handler);  // Cleanup on unmount
        };
    }, [searchTerm]);  // Run effect on search term changes

    // Handle single tag selection
    const handleCheckboxChange = (tagId) => {
        const newSelectedTag = selectedTag === tagId ? null : tagId;  // Toggle selection

        setSelectedTag(newSelectedTag);  // Set the selected tag
        onTagSelectionChange(newSelectedTag ? newSelectedTag : '');  // Pass the selected tag to parent as an array
    };

    // Filter the filters list based on the debounced search term
    const filteredFilters = filters.filter((filter) =>
        filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);  // Update the search term on input change
    };

    return (
        <div className={styles.IntegrationsSidebar}>
            <div className={styles.searchContainer}>
                <SearchIcon className={styles.searchIcon} />
                <input
                    type="text"
                    placeholder="Search..."
                    className={styles.searchInput}
                    value={searchTerm}  // Bind search input value to searchTerm state
                    onChange={handleSearch}  // Trigger search input change handler
                />
                <hr className={styles.dottedLine} />
            </div>

            <div className={styles.menu}>
                <div className={styles.listHeader} onClick={handleToggle}>
                    <div className={styles.listHeaderContent}>
                        <SystemSettingsIcon className={styles.icon} />
                        <span className={styles.sectionTitle}>By System</span>
                    </div>
                    {isOpen ? (
                        <DropdownIconOpen className={styles.expandIcon} />
                    ) : (
                        <DropdownIconClosed className={styles.expandIcon} />
                    )}
                </div>

                {isOpen && (
                    <ul className={styles.filterList}>
                        {/* Display filtered list based on search input */}
                        {filteredFilters.map((filter) => (
                            <li key={filter.id} className={styles.listItem}>
                                <input
                                    type="checkbox"
                                    checked={selectedTag === filter.id}  // Only one checkbox can be checked
                                    className={styles.checkbox}
                                    onChange={() => handleCheckboxChange(filter.id)}  // Handle selection
                                />
                                <Tooltip title={filter.name} arrow>
                                    <span className={styles.listItemText}>{filter.name}</span>
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default IntegrationsSidebar;
