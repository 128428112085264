import React from 'react';

const JobSchedule = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh' // Makes the div full height of the viewport
    }} >
      <h1>Job Schedule</h1>
      <p>This is the Job Schedule content area. Work is in progress</p>
    </div>
  );
};

export default JobSchedule;
