// actions/authActions.js


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const STORE_TOKENS = 'STORE_TOKENS'; // Action to store tokens
export const CLEAR_TOKENS = 'CLEAR_TOKENS'; // For clearing tokens on logout


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';


export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';


export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const CLEAR_SIGNUP_MESSAGE = 'CLEAR_SIGNUP_MESSAGE';
export const STORE_USER_DETAILS = 'STORE_USER_DETAILS';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RE_AUTHENTICATE = 'RE_AUTHENTICATE';

// Action creators
export const loginRequest = (credentials) => ({
    type: LOGIN_REQUEST,
    payload: credentials,
});


export const loginSuccess = (tokens) => ({
    type: LOGIN_SUCCESS,
    payload: tokens,
});


export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
});


export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
});
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});
export const refreshTokenRequest = () => ({
    type: REFRESH_TOKEN_REQUEST,
});


export const refreshTokenSuccess = (tokens) => ({
    type: REFRESH_TOKEN_SUCCESS,
    payload: tokens,
});


export const refreshTokenFailure = () => ({
    type: REFRESH_TOKEN_FAILURE,
});




// for Registration  Action Creators
export const registerRequest = (userData) => ({
    type: REGISTER_REQUEST,
    payload: userData,
});


export const registerSuccess = (message) => ({
    type: REGISTER_SUCCESS,
    payload: message,
});


export const registerFailure = (error) => ({
    type: REGISTER_FAILURE,
    payload: error,
});
export const clearSignupMessage =() =>{
   return{
        type:CLEAR_SIGNUP_MESSAGE
   }
}
export const storeTokens = (accessToken, refreshToken) => ({
    type: STORE_TOKENS,
    payload: { accessToken, refreshToken },
});
export const clearTokens = () => {
    return {
      type: CLEAR_TOKENS,
    };
  };
export const storeUserDetails = (userDetails) => ({
    type: STORE_USER_DETAILS,
    payload: userDetails,
});

// Action to set the access token
export const setAccessToken = (token) => ({
    type: SET_ACCESS_TOKEN,
    payload: token,
});

// Action to logout the user
export const logoutUser = () => ({
    type: LOGOUT_USER,
});
// Action Creators
export const reAuthenticate = () => {
    return {
        type: RE_AUTHENTICATE
    };
};