import React from 'react';
import WorkflowTile from '../../components/WorkflowTile/WorkflowTile';
import styles from './WorkflowGrid.module.css';

import dataIcon from '../../../../assetts/images/data.svg';
import catalogIcon from '../../../../assetts/images/catalog.svg';
import cartIcon from '../../../../assetts/images/cart.svg';

const iconMap = {
  data: dataIcon,
  catalog: catalogIcon,
  cart: cartIcon,
};

const WorkflowGrid = ({ tiles, loading, error }) => {
  if (loading) {
    return <div>Loading workflows...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.gridContainer}>
      {tiles.map((tile) => (
        <WorkflowTile
          id={tile.id}
          icon={iconMap[tile.icon] || iconMap['data']}
          title={tile.name}
          category={tile.functions.join('/')}
        />
      ))}
    </div>
  );
};

export default WorkflowGrid;
